import React, { useContext } from "react"

import { AnalyticsContext } from "../context"
import Button from "../components/global/Button"
import CardsSection from "../components/sections/CardsSection"
import CtaSection from "../components/sections/CtaSection"
import FaqAccordionSection from "../components/sections/FaqAccordionSection"
import Layout from "../components/global/Layout"
import QuoteSection from "../components/sections/QuoteSection"
import { getPageSlug } from '../../helpers';
import { queries } from "../breakpoints"
import styled from 'styled-components';

const Local = ({ location, pageContext }) => {
    const { handleTealiumEvent } = useContext(AnalyticsContext)
    const {
        city,
        seoTitle,
        seoDescription,
        faqs,
        restaurantCards,
        headerPattern
    } = pageContext
    return (
        <Layout
            location={location}
            title={seoTitle}
            description={seoDescription}
            utag_data={{
                pageGroup: "grubhub - lead generation",
                subGroup: "explore local cities",
                pageName: `explore local cities - ${city}`,
                brand: "grubhub"
            }}
            faqSeoList={faqs}
        >
            <StyledHeader pattern={headerPattern}>
                <Content>
                    <Headline>{`${city} Grubhub Corporate Accounts`}</Headline>
                    <Button
                        to='/get-started'
                        onClick={() => handleTealiumEvent({ eventcategory: `${getPageSlug()} interaction`, eventaction: "get started_cta", eventlabel: "hero banner" })}
                    >
                        Get started
                    </Button>
                </Content>
            </StyledHeader>
            <CardsSection headline={`${city}'s restaurants, delivered to your team!`} subheadline={`${seoDescription} Check out these ${city} restaurants our clients love:`} cards={restaurantCards} cardsPerRow={3} />
            <QuoteSection
                quote='"For someone like me, who manages logistics and accommodations for client meetings, dietary restrictions and large team events, Grubhub is my best partner."'
                author="Carol K"
                position="Planning Operations Manager at GSD&M"
                imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/v1627927171/Grubhub%20Corporate%20Website/2021/Partner%20Logos/PARTNERLOGOS-GSDM.png"
            />
            <CtaSection headline="See what we can do for you" backgroundColor="#F9F9F9"/>
            <FaqAccordionSection faqs={faqs}   backgroundColor="white"/>
        </Layout>
    )
}

export default Local

const getPatternUrl = (pattern, size) => {
    /**
     * This function is used to pull the correct pattern for the local page. The pattern is set in the local-pages.js file.
     * In the styled component, for each breakpoint it will call this function with the pattern name which is sent in as a prop
     * It will then use that url as the background-image url
     */
    switch (pattern) {
        case 'pizza':
            if( size === "desktop" ) {
                return 'https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1628275114/Grubhub%20Corporate%20Website/2021/Header%20Images/Illustrated%20Headers%20/8-Local%20City%20Pages/1-NYC-Pizza/Pattern-HEADER-Desktop-NYC-TEST_2-1400x500.png'
            } else if ( size === "tablet" ) {
                return 'https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1628266864/Grubhub%20Corporate%20Website/2021/Header%20Images/Illustrated%20Headers%20/8-Local%20City%20Pages/1-NYC-Pizza/Pattern-HEADER-Tablet-NYC-Test1.png'
            } else if ( size === "mobile" ) {
                return 'https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1628266864/Grubhub%20Corporate%20Website/2021/Header%20Images/Illustrated%20Headers%20/8-Local%20City%20Pages/1-NYC-Pizza/Pattern-HEADER-Mobile-NYC-Test1.png'
            }

            break;

        case 'poke':
            if( size === "desktop" ) {
                return 'https://res.cloudinary.com/grubhub-marketing/image/upload/v1628280422/Grubhub%20Corporate%20Website/2021/Header%20Images/Illustrated%20Headers%20/8-Local%20City%20Pages/9-HOUSTON-Poke/Pattern-HEADER-Desktop-POKE-Houston.png'
            } else if ( size === "tablet" ) {
                return 'https://res.cloudinary.com/grubhub-marketing/image/upload/v1628266861/Grubhub%20Corporate%20Website/2021/Header%20Images/Illustrated%20Headers%20/8-Local%20City%20Pages/9-HOUSTON-Poke/Pattern-HEADER-Tablet-HOUSTON-Poke.png'
            } else if ( size === "mobile" ) {
                return 'https://res.cloudinary.com/grubhub-marketing/image/upload/v1628280422/Grubhub%20Corporate%20Website/2021/Header%20Images/Illustrated%20Headers%20/8-Local%20City%20Pages/9-HOUSTON-Poke/Pattern-HEADER-Mobile-POKE-Houston.png'
            }
            break;

        case 'cake':
            if( size === "desktop" ) {
                return 'https://res.cloudinary.com/grubhub-marketing/image/upload/v1628280319/Grubhub%20Corporate%20Website/2021/Header%20Images/Illustrated%20Headers%20/8-Local%20City%20Pages/13-CAMBRIDGE-Dessert/Pattern-HEADER-Desktop-Cake-Cambridge.png'
            } else if ( size === "tablet" ) {
                return 'https://res.cloudinary.com/grubhub-marketing/image/upload/v1628266860/Grubhub%20Corporate%20Website/2021/Header%20Images/Illustrated%20Headers%20/8-Local%20City%20Pages/13-CAMBRIDGE-Dessert/Pattern-HEADER-Tablet-CAMBRIDGE.png'
            } else if ( size === "mobile" ) {
                return 'https://res.cloudinary.com/grubhub-marketing/image/upload/v1628280319/Grubhub%20Corporate%20Website/2021/Header%20Images/Illustrated%20Headers%20/8-Local%20City%20Pages/13-CAMBRIDGE-Dessert/Pattern-HEADER-Mobile-CAKE-Cambridge.png'
            }
            break;

        case 'coffee':
            if( size === "desktop" ) {
                return 'https://res.cloudinary.com/grubhub-marketing/image/upload/v1628280361/Grubhub%20Corporate%20Website/2021/Header%20Images/Illustrated%20Headers%20/8-Local%20City%20Pages/5-SEATTLE-Coffee/Pattern-HEADER-Desktop-COFFEE-Seattle.png'
            } else if ( size === "tablet" ) {
                return 'https://res.cloudinary.com/grubhub-marketing/image/upload/v1628266858/Grubhub%20Corporate%20Website/2021/Header%20Images/Illustrated%20Headers%20/8-Local%20City%20Pages/5-SEATTLE-Coffee/Pattern-HEADER-Tablet-SEATTLE.png'
            } else if ( size === "mobile" ) {
                return 'https://res.cloudinary.com/grubhub-marketing/image/upload/v1628280361/Grubhub%20Corporate%20Website/2021/Header%20Images/Illustrated%20Headers%20/8-Local%20City%20Pages/5-SEATTLE-Coffee/Pattern-HEADER-Mobile-COFFEE-Seattle.png'
            }
            break;

        case 'hot dog':
            if( size === "desktop" ) {
                return 'https://res.cloudinary.com/grubhub-marketing/image/upload/v1628280373/Grubhub%20Corporate%20Website/2021/Header%20Images/Illustrated%20Headers%20/8-Local%20City%20Pages/3-CHI-HotDog/Pattern-HEADER-Desktop-HOT_DOGS.png'
            } else if ( size === "tablet" ) {
                return 'https://res.cloudinary.com/grubhub-marketing/image/upload/v1628266859/Grubhub%20Corporate%20Website/2021/Header%20Images/Illustrated%20Headers%20/8-Local%20City%20Pages/3-CHI-HotDog/Pattern-HEADER-Tablet-CHI.png'
            } else if ( size === "mobile" ) {
                return 'https://res.cloudinary.com/grubhub-marketing/image/upload/v1628280373/Grubhub%20Corporate%20Website/2021/Header%20Images/Illustrated%20Headers%20/8-Local%20City%20Pages/3-CHI-HotDog/Pattern-HEADER-Mobile-HOT_DOGS.png'
            }
            break;

        case 'nachos':
            if( size === "desktop" ) {
                return 'https://res.cloudinary.com/grubhub-marketing/image/upload/v1628280129/Grubhub%20Corporate%20Website/2021/Header%20Images/Illustrated%20Headers%20/8-Local%20City%20Pages/8-DALLAS-TexMex/Pattern-HEADER-Desktop-TEXMEX-Dallas.png'
            } else if ( size === "tablet" ) {
                return 'https://res.cloudinary.com/grubhub-marketing/image/upload/v1628266862/Grubhub%20Corporate%20Website/2021/Header%20Images/Illustrated%20Headers%20/8-Local%20City%20Pages/8-DALLAS-TexMex/Pattern-HEADER-Tablet-DALLAS-TexMex.png'
            } else if ( size === "mobile" ) {
                return 'https://res.cloudinary.com/grubhub-marketing/image/upload/v1628280129/Grubhub%20Corporate%20Website/2021/Header%20Images/Illustrated%20Headers%20/8-Local%20City%20Pages/8-DALLAS-TexMex/Pattern-HEADER-Mobile-TEXMEX-Dallas.png'
            }
            break;

        case 'sushi':
            if( size === "desktop" ) {
                return 'https://res.cloudinary.com/grubhub-marketing/image/upload/v1628278629/Grubhub%20Corporate%20Website/2021/Header%20Images/Illustrated%20Headers%20/8-Local%20City%20Pages/4-LA-Sushi/Pattern-HEADER-Desktop-SUSHI-LA.png'
            } else if ( size === "tablet" ) {
                return 'https://res.cloudinary.com/grubhub-marketing/image/upload/v1628266859/Grubhub%20Corporate%20Website/2021/Header%20Images/Illustrated%20Headers%20/8-Local%20City%20Pages/4-LA-Sushi/Pattern-HEADER-Tablet-LA-Sushi.png'
            } else if ( size === "mobile" ) {
                return 'https://res.cloudinary.com/grubhub-marketing/image/upload/v1628278629/Grubhub%20Corporate%20Website/2021/Header%20Images/Illustrated%20Headers%20/8-Local%20City%20Pages/4-LA-Sushi/Pattern-HEADER-Mobile-Sushi-LA.png'
            }
            break;

        case 'salad':
            if( size === "desktop" ) {
                return 'https://res.cloudinary.com/grubhub-marketing/image/upload/v1628278594/Grubhub%20Corporate%20Website/2021/Header%20Images/Illustrated%20Headers%20/8-Local%20City%20Pages/2-SF-Salad/Pattern-HEADER-Desktop-SALAD-SF.png'
            } else if ( size === "tablet" ) {
                return 'https://res.cloudinary.com/grubhub-marketing/image/upload/v1628266863/Grubhub%20Corporate%20Website/2021/Header%20Images/Illustrated%20Headers%20/8-Local%20City%20Pages/2-SF-Salad/Pattern-HEADER-Tablet-SF.png'
            } else if ( size === "mobile" ) {
                return 'https://res.cloudinary.com/grubhub-marketing/image/upload/v1628278594/Grubhub%20Corporate%20Website/2021/Header%20Images/Illustrated%20Headers%20/8-Local%20City%20Pages/2-SF-Salad/Pattern-HEADER-Mobile-Salad-SF.png'
            }
            break;

        default:
            break;
    }
}

const StyledHeader = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    padding: 80px 0px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    min-height: 500px;
    background-image: url(${props => getPatternUrl(props.pattern, "desktop")});
    @media (${queries.max_break_md}){
        background-image: url(${props => getPatternUrl(props.pattern, "tablet")});
    }
    @media (${queries.max_break_sm}){
        background-image: url(${props => getPatternUrl(props.pattern, "mobile")});
    }
`;

const Headline = styled.h1`
    color: white;
    font-weight: bold;  
    max-width: 650px;
    margin: 0px auto 20px;
    font-size: clamp(var(--fontSize-8), 3.5vw, 7rem);
    @media (${queries.max_break_sm}){
        font-size: var(--fontSize-6);
    }
`;

const Content = styled.div`
    max-width: 900px;
    margin:0 auto;

`;
